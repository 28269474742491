export const environment = {
  production: false,
  backendUrl: 'https://api.dev.mygrowth.siemens.cloud/v3/', // dev
  /**
   * Anti-Pattern.
   * The translations feature consumes both stage translations, thus both are needed to comply with the requirements
   */
  translationsUrlDev: 'https://translations.dev.mygrowth.siemens.cloud/',
  translationsUrl: 'https://translations.mygrowth.siemens.cloud/',
  filesCdnUrl: 'https://dev.files.mygrowth.siemens.cloud/',
  trackingUrl: '', // not used for dev
  url: 'https://dev.mygrowth.siemens.cloud/',
  scdUrl: 'https://api.dev.scd.suites.siemens.cloud/v2/',
  scdApiKey: 'ycQgHF1JKc7zaiCBDpYud8Us9C4hK0Qz5qVrdtqQ',
  translationServiceUrl: 'https://api.translationq.siemens.cloud/api/translation/translatetextdeepl',
  translationToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IkdCU19HTVNfTXlHcm93dGgiLCJBcHBJZCI6IkdCU19HTVNfTXlHcm93dGgiLCJyb2xlIjoiR1RTVXNlciIsIm5iZiI6MTcxOTkzMTU2OCwiZXhwIjoxNzUxNDY3NTY4LCJpYXQiOjE3MTk5MzE1Njh9.1SMZWd2IUrCrqSPNVv5JT9iMO-3Y0aX33gnCHa3VEwM',
  authentication: {
    authWellknownEndpointUrl: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg/.well-known/openid-configuration',
    authority: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_3plO1v5sg',
    unauthorizedRoute: '/forbidden',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    clientId: '3r3kurgp7ffo8punkvk64v47ic',
    scope: 'openid profile email',
    responseType: 'code',
    silentRenew: true,
    autoUserInfo: true,
    useRefreshToken: true,
    logLevel: 1,
    renewTimeBeforeTokenExpiresInSeconds: 90,
    allowUnsafeReuseRefreshToken: true,
  },
  newTracking: {
    endpointStage: 'dev',
    apiKey: 'VJhdpUq0Dm5wgoyJg9P239C1aqXXGENF3ipUEspF',
  },
  juno: {
    templateIds: {
      registerStrengthscope: 'd0759c4ae78f496d83a1ea41c0795c12',
      seasonCalendar24: '2cc6c075-9f79-4181-86bd-07ed57b4441b',
    },
    errorReceivers: ['sack.michael@siemens.com', 'matthias.kurzenberg@siemens.com'],
  },
  forbiddenOrgCodes: ['SHS', 'SE'],
  testerGIDs: ['Z004PKXE'],
};
