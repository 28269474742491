export const environment = {
  production: true,
  backendUrl: 'https://api.mygrowth.siemens.cloud/v3/', // prod
  /**
   * Anti-Pattern.
   * The translations feature consumes both stage translations, thus both are needed to comply with the requirements
   */
  translationsUrlDev: 'https://translations.dev.mygrowth.siemens.cloud/',
  translationsUrl: 'https://translations.mygrowth.siemens.cloud/',
  filesCdnUrl: 'https://files.mygrowth.siemens.cloud/',
  url: 'https://mygrowth.siemens.cloud/',
  scdUrl: 'https://api.scd.suites.siemens.cloud/v2/',
  scdApiKey: 'mazxauGXzV65j1pfdrg4y8Q9KXuiBzmH85T7Gi8j',
  translationServiceUrl: 'https://api.translationq.siemens.cloud/api/translation/translatetext',
  translationToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IkdCU19HTVNfTXlHcm93dGgiLCJBcHBJZCI6IkdCU19HTVNfTXlHcm93dGgiLCJyb2xlIjoiR1RTVXNlciIsIm5iZiI6MTcxOTkzMTU2OCwiZXhwIjoxNzUxNDY3NTY4LCJpYXQiOjE3MTk5MzE1Njh9.1SMZWd2IUrCrqSPNVv5JT9iMO-3Y0aX33gnCHa3VEwM',
  authentication: {
    authWellknownEndpointUrl: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_tVz7DaEVM/.well-known/openid-configuration',
    authority: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_tVz7DaEVM',
    unauthorizedRoute: '/forbidden',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    clientId: '4a8v5nsnee9v58d3fvou1q18eh',
    scope: 'openid profile email',
    responseType: 'code',
    silentRenew: true,
    autoUserInfo: true,
    useRefreshToken: true,
    renewTimeBeforeTokenExpiresInSeconds: 90,
    allowUnsafeReuseRefreshToken: true,
  },
  newTracking: {
    endpointStage: 'prod',
    apiKey: 'PvJVqypDiD1PfpGYVVXio94ALyYKNED2AkLbiPP8',
  },
  juno: {
    templateIds: {
      registerStrengthscope: 'd0759c4ae78f496d83a1ea41c0795c12',
      seasonCalendar24: '2cc6c075-9f79-4181-86bd-07ed57b4441b'
    },
    errorReceivers: ['sack.michael@siemens.com', 'matthias.kurzenberg@siemens.com'],
  },
  forbiddenOrgCodes: ['SHS', 'SE'],
  testerGIDs: ['Z004PKXE'],
};
