import { MYG_LANGUAGE_CODE } from '../enums/language-code.enum';
import { IMachineTranslationLanguageCode } from '../interfaces/IMachineTranslation';

export class TranslationOperations {
  public static translationValidToCutTag(tag: string): boolean {
    const tags = [
      'div',
      '/div',
      'p',
      '/p',
      'table',
      '/table',
      'tbody',
      '/tbody',
      '/tr',
      '/td',
      'br',
      'h1',
      '/h1',
      'h2',
      '/h2',
      'h3',
      '/h3',
      'ul',
      '/ul',
      'li',
      '/li',
      'ol',
      '/ol',
      'img',
    ];
    return tags.includes(tag.toLowerCase());
  }

  public static translationValidToReduceTag(tag: string): boolean {
    const tags = ['table', '/table', 'tbody', '/tbody', '/tr', '/td'];
    return tags.includes(tag.toLowerCase());
  }

  public static unescapeHtml(text: string): string {
    return decodeURI(text)
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#58;/g, ':')
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
      .replace(/&#034;/g, '"');
    // .replace(/&#039;/g, '\'') removed because it broke linting but function is never used
  }

  public static decodeUnicodeCharacters(text: string): string {
    const uniCodes = text.match(/&[ ]?#[0-9]{1,5};/g);
    const uniqCodes = [...new Set(uniCodes)];
    for (const uniq of uniqCodes) {
      text = text.replace(new RegExp(uniq, 'g'), decodeURI(uniq.replace(/ /g, '')));
    }
    return text;
  }

  public static mapMygLanguageTranslationCode(lang: MYG_LANGUAGE_CODE): IMachineTranslationLanguageCode | undefined {
    switch (lang) {
      case MYG_LANGUAGE_CODE.EN:
        return IMachineTranslationLanguageCode.EN;
      case MYG_LANGUAGE_CODE.DE:
        return IMachineTranslationLanguageCode.DE;
      case MYG_LANGUAGE_CODE.ES:
        return IMachineTranslationLanguageCode.ES;
      case MYG_LANGUAGE_CODE.FR_CA:
        return IMachineTranslationLanguageCode.FR;
      case MYG_LANGUAGE_CODE.PL:
        return IMachineTranslationLanguageCode.PL;
      case MYG_LANGUAGE_CODE.PT_BR:
        return IMachineTranslationLanguageCode.PT_BR;
      case MYG_LANGUAGE_CODE.ZH:
        return IMachineTranslationLanguageCode.ZH;
      default:
        return undefined;
    }
  }
}
