import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TRACKING_ITEM } from '../enums/tracking_item.enum';
import { TrackingService } from './tracking.service';

export interface DialogItem {
  dialogContent: ComponentType<unknown> | TemplateRef<unknown>;
  config: MatDialogConfig;
}

@Injectable({
  providedIn: 'root',
})
export class PopupHandlerService {
  private _popupsQueue: DialogItem[] = [];
  private _dialogOpen = false;

  constructor(private _dialog: MatDialog, private trackingService: TrackingService) {}

  public openDialog(dialogItem: DialogItem): void {
    if (this._dialogOpen) {
      this._popupsQueue.push(dialogItem);
      return;
    }
    this._dialogOpen = true;
    const dialogRef = this._dialog.open(dialogItem.dialogContent, dialogItem.config);

    dialogRef.afterClosed().subscribe(() => {
      this._dialogOpen = false;
      if (this._popupsQueue.length > 0) {
        this.openDialog(this._popupsQueue.shift());
      }
      this.trackClick('shared.close');
    });
  }

  private trackClick(trackingString: string): void {
    let trackingModifiedString = trackingString ? this.trackingService.getEngTranslation(trackingString) : '';
    trackingModifiedString = trackingModifiedString + ':[from]' + 'growthTalksVideoPopUp';
    this.trackingService.addTrackingItem(TRACKING_ITEM.button, trackingModifiedString);
  }
}
