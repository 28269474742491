import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translationExists',
})
@Injectable()
export class TranslationExistsPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(key: string): boolean {
    const instant = this.translateService.instant(key);
    return instant !== key && instant !== '';
  }
}
