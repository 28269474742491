import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  constructor(private http: HttpClient) {}

  getVideoUrl(videoId: string, lang: string = 'en'): Observable<string> {
    return this.http.get<string>(`${environment.backendUrl}downloads/singleVideo/${lang}?id=${videoId}`);
  }  
}
