import { Component, Input } from '@angular/core';
import { IWizardSidenavItem } from '../../interfaces/IWizardSidenavItem';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { JourneyWarningDialogComponent } from 'src/app/self-reflection-journeys/shared/components/journey-warning-dialog/journey-warning-dialog.component';
import { tap } from 'rxjs/internal/operators/tap';

@Component({
  selector: 'gt-wizard-sidenav',
  templateUrl: './wizard-sidenav.component.html',
  styleUrl: './wizard-sidenav.component.scss',
})
export class WizardSidenavComponent {
  @Input() sideNav: IWizardSidenavItem[];
  @Input() activeCategory: string;
  @Input() activeStep: string;
  @Input() translationStringPrefix: string = '';
  @Input() imgPrefix: string;
  @Input() imgPaths: { [key: string]: string };
  @Input() showSubSteps: boolean = true;
  @Input() clickable: boolean = false;
  @Input() confirmationNeeded: boolean = false;
  @Input() routePrefix: string;
  @Input() allNavigationAllowed: boolean = false;
  @Input() openSubIfExisting: boolean = false;
  @Input() continueAtUncompleted: boolean = false;
  @Input() homeNavigation: boolean = false;
  @Input() homeNavigationLabel: string = 'shared.home';
  @Input() homeNavigationImg: string;

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) {}

  public navigateTo(category?: IWizardSidenavItem): void {
    if (this.confirmationNeeded) {
      this._navigateWithConfirmation(category);
    } else {
      this._navigateWithoutConfirmation(category);
    }
  }

  private _navigateWithoutConfirmation(category?: IWizardSidenavItem): void {
    if (!category) {
      this.router.navigate([this.routePrefix], { queryParamsHandling: 'preserve' });
      return;
    }

    const baseRoute = [this.routePrefix + category.id];
    if (this.openSubIfExisting) {
      const subId = this.continueAtUncompleted ? category.sub.find((sub: IWizardSidenavItem) => !sub.completed)?.id : category.sub[0]?.id;

      if (subId) {
        baseRoute.push(subId);
      }
    }
    this.router.navigate(baseRoute, { queryParamsHandling: 'preserve' });
  }

  private _navigateWithConfirmation(category?: IWizardSidenavItem): void {
    const dialogRef = this.dialog.open(JourneyWarningDialogComponent, { data: 'esrj.warning-dialog.switchModule.', disableClose: true });
    dialogRef
      .afterClosed()
      .pipe(
        tap(result => {
          if (result === true) {
            this._navigateWithoutConfirmation(category);
          } else {
            return;
          }
        }),
      )
      .subscribe();
  }
}
