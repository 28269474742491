import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesGuard } from './auth/services/roles-guard.service';
import { LanguageGuard } from './auth/services/language-guard.service';
import { CompanyGuard } from './auth/services/company-guard.service';
import { BrandBuilderGuard } from './auth/services/brandbuilder-guard.service';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { ForbiddenGuard } from './auth/services/forbidden-guard.service';
import { UserRolesEnum } from './shared/enums/user_roles.enum';
import { DevGuard } from './auth/services/dev-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./my-growth-home/my-growth-home.module').then(m => m.MyGrowthHomeModule),
    canActivate: [CompanyGuard],
    canLoad: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'growth-talks',
    loadChildren: () => import('./growth-talks/growth-talks.module').then(m => m.GrowthTalksModule),
    canActivate: [CompanyGuard],
    data: {
      title: 'Siemens MyGrowth',
    },
    canLoad: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'learning',
    loadChildren: () => import('./learning/learning.module').then(m => m.LearningModule),
    canActivate: [CompanyGuard],
    data: {
      title: 'Siemens MyGrowth',
    },
    canLoad: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'career',
    loadChildren: () => import('./career/career.module').then(m => m.CareerModule),
    canActivate: [CompanyGuard],
    data: {
      title: 'Siemens MyGrowth',
    },
    canLoad: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'self-reflection',
    loadChildren: () => import('./self-reflection/self-reflection.module').then(m => m.SelfReflectionModule),
    canActivate: [CompanyGuard],
    data: {
      title: 'Siemens MyGrowth',
    },
    canLoad: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'help',
    loadChildren: () => import('./help-faq/help-faq.module').then(m => m.HelpFaqModule),
    canActivate: [CompanyGuard],
    data: {
      title: 'Siemens MyGrowth',
    },
    canLoad: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [CompanyGuard, RolesGuard],
    data: {
      allowedRoles: [
        UserRolesEnum.ADMIN,
        UserRolesEnum.TRANSLATION_MANAGER,
        UserRolesEnum.DOWNLOAD_MANAGER,
        UserRolesEnum.STRENGTHSCOPE_MANAGER,
        UserRolesEnum.SURVEY_MANAGER,
      ],
      title: 'Siemens MyGrowth',
    },
    canLoad: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'mybrandbuilder',
    loadChildren: () => import('./brandbuilder/brandbuilder.module').then(m => m.BrandbuilderModule),
    canActivate: [CompanyGuard, LanguageGuard, BrandBuilderGuard],
    data: {
      allowedRoles: [UserRolesEnum.ADMIN, UserRolesEnum.TRANSLATION_MANAGER],
      title: 'Siemens My Brand Builder',
      languages: ['en', 'de'],
      redirectUrl: '/',
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canLoad: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'leaders',
    loadChildren: () => import('./leaders/leaders.module').then(m => m.LeadersModule),
    canActivate: [CompanyGuard, LanguageGuard],
    data: {
      title: 'Siemens MyGrowth for Leaders',
      languages: ['en', 'de'],
      redirectUrl: '/',
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canLoad: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'self-reflection-journey',
    loadChildren: () => import('./self-reflection-journeys/self-reflection-journey/self-reflection-journey.module').then(m => m.SelfReflectionJourneyModule),
    canActivate: [CompanyGuard, LanguageGuard],
    data: {
      title: 'Siemens Self-Reflection Journey',
      languages: ['en', 'de'],
      redirectUrl: '/',
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canLoad: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'ethical-self-reflection',
    loadChildren: () =>
      import('./self-reflection-journeys/ethical-self-reflection/ethical-self-reflection-journey.module').then(m => m.EthicalSelfReflectionJourneyModule),
    canActivate: [CompanyGuard, LanguageGuard, DevGuard],
    data: {
      title: 'Siemens Self-Reflection Journey',
      languages: ['en', 'de'],
      redirectUrl: '/',
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canLoad: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'forbidden',
    loadChildren: () => import('./forbidden/forbidden.module').then(m => m.ForbiddenModule),
    canActivate: [ForbiddenGuard],
    canLoad: [AutoLoginPartialRoutesGuard],
  },
];

// removed the scroll to top restoration, and used the anchor tags to prevent from scrolling up and down in the downloads
@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
