import { IUserInfo } from './shared/interfaces/IUserInfo';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, from, of } from 'rxjs';
import { DocumentPreviewService } from './shared/services/document-preview.service';
import { HrefInterceptorService } from './shared/services/href-interceptor.service';
import { LoadingIndicatorService } from './shared/services/loading-indicator.service';
import { SearchService } from './shared/services/search.service';
import { TrackingService } from './shared/services/tracking.service';
import { MatDialog } from '@angular/material/dialog';
import { SurveyDialogComponent } from './shared/components/survey/survey-dialog/survey-dialog.component';
import { ISurveyConfig } from './shared/interfaces/ISurveyConfig';
import { SurveyService } from './shared/services/survey.service';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ScdService } from './shared/services/scd.service';
import { TRACKING_ITEM } from './shared/enums/tracking_item.enum';
import { Select, Store } from '@ngxs/store';
import { GetAuthData, GetUserConfig, GetUserProfile } from './shared/state-management/user/user.actions';
import { UserState } from './shared/state-management/user/user.state';
import { IUserData } from './shared/interfaces/IUserData';
import { IUserConfig } from './shared/interfaces/IUserConfig';
import { ActivePopupsService } from './shared/services/active-popups.service';
import { POPUP_TYPE } from './shared/enums/popup_type.enum';
import { MYG_LANGUAGE_CODE } from './shared/enums/language-code.enum';
import { SelfReflectionJourneyService } from './shared/services/self-reflection-journey.service';
import { ResetESRJStatus } from './shared/state-management/ethicalSelfReflection/ethicalSelfReflection.actions';
import { JourneyWarningDialogComponent } from './self-reflection-journeys/shared/components/journey-warning-dialog/journey-warning-dialog.component';
import { BrandbuilderService } from './shared/services/brandbuilder.service';

@Component({
  selector: 'gt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  @Select(UserState.loggedUser) userData$: Observable<IUserData>;
  @Select(UserState.userConfig) userConfig$: Observable<IUserConfig>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  protected userConfig: IUserConfig;
  // added both variables for better accessibility
  protected showBBheader: boolean = false;
  protected showMGheader: boolean = false;
  protected showLeadersHeader: boolean = false;
  protected showLoadingIndicator: boolean = false;
  protected showFooter: boolean = false;
  protected showSurveyBtn: boolean = false;
  protected showSelfReflectionHeader: boolean = false;
  protected selfReflectionJourneyLanguages: MYG_LANGUAGE_CODE[] = [MYG_LANGUAGE_CODE.EN, MYG_LANGUAGE_CODE.DE];
  protected brandBuilderLanguages: MYG_LANGUAGE_CODE[] = [MYG_LANGUAGE_CODE.EN, MYG_LANGUAGE_CODE.DE];
  protected showEthicalSelfReflectionHeader: boolean = false;
  protected ethicalSelfReflectionJourneyLanguages: MYG_LANGUAGE_CODE[] = [MYG_LANGUAGE_CODE.EN, MYG_LANGUAGE_CODE.DE];
  private currentUrl: string;
  private subs: Subscription[] = [];
  private previousUrl: string;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private tracking: TrackingService,
    private searchService: SearchService,
    private loadingIndicator: LoadingIndicatorService,
    private hrefInterceptor: HrefInterceptorService,
    private activatedRoute: ActivatedRoute,
    private documentPreview: DocumentPreviewService,
    private dialog: MatDialog,
    private surveyService: SurveyService,
    private scdService: ScdService,
    private trackingService: TrackingService,
    private store: Store,
    private popupService: ActivePopupsService,
    private srjService: SelfReflectionJourneyService,
    private bbService: BrandbuilderService,
  ) {
    this.hrefInterceptor.initialise();
    this.setComponentsVisibility(window.location.pathname);
    this.translate.setDefaultLang('en');
    this.handleFeedbackValidation();
    this.trackRouteChange();
    this.activatedRoute.queryParams.subscribe(async params => {
      // added this check because for now downloads have their own logic for asset preview
      if (params.asset && !this.router.url.includes('/growth-talks/resources')) {
        this.documentPreview.assetPreview(params.asset);
      }
    });

    this.subs.push(
      this.loadingIndicator.currentShowLoadingIndicator.subscribe(status => {
        this.showLoadingIndicator = status;
      }),
    );

    this.subs.push(
      this.surveyService.showSurveyBtn.subscribe(status => {
        this.showSurveyBtn = status;
      }),
    );
    this.store.dispatch(new GetAuthData());
    this.store.dispatch(new GetUserProfile());
    this.store.dispatch(new GetUserConfig());
    this.getUserConfig();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  public showSurvey(track: boolean, surveyConfig?: ISurveyConfig): void {
    if (surveyConfig) {
      this.openSurveyDialog(track, surveyConfig);
    } else {
      this.surveyService.getActiveSurveyConfig().subscribe(result => {
        if (!(result instanceof HttpErrorResponse)) {
          this.openSurveyDialog(track, result);
        } else {
          console.error(result);
        }
      });
    }
  }

  protected async close(): Promise<void> {
    this.loadingIndicator.updateLoadingIndicatorStatus(true);
    await this.bbService.save();
    this.loadingIndicator.updateLoadingIndicatorStatus(false);
    this.router.navigateByUrl('/');
  }

  protected selfReflectionJourneyClosed(): void {
    this.srjService.saveAndCloseUpdate(true);
  }

  protected ethicalSelfReflectionJourneyClosed(): void {
    const dialogRef = this.dialog.open(JourneyWarningDialogComponent, { data: 'esrj.warning-dialog.close_app.', disableClose: true });
    dialogRef
      .afterClosed()
      .pipe(
        filter(result => result),
        tap(() => {
          this.store.dispatch(new ResetESRJStatus());
          this.router.navigate(['/'], { fragment: 'top-header' });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  protected closeSearch(): void {
    this.searchService.updateSearchState(false);
  }

  private getUserConfig(): void {
    this.userConfig$
      .pipe(
        filter(userConfig => !!userConfig),
        tap(userConfig => {
          this.userConfig = userConfig;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private trackRouteChange(): void {
    this.router.events.subscribe(res => {
      if (res instanceof RoutesRecognized && !res.url.startsWith('/auth')) {
        this.previousUrl = this.tracking.cleanRoute(this.currentUrl);
        this.currentUrl = this.tracking.cleanRoute(res.urlAfterRedirects);
        if (this.previousUrl !== this.currentUrl) {
          this.tracking.addTrackingItem(TRACKING_ITEM.route, this.trackingService.cleanRoute(res.urlAfterRedirects));
        }
      }
      if (res instanceof NavigationEnd) {
        this.setComponentsVisibility(res.url);

        window['ste_statistic'] = window['ste_statistic'] || [];
        window['ste_statistic'].push({
          action: 'tracking.started',
          callback: () => {
            // The code to remove the query string parameter goes here.
            // remove sitc parameter manually to prevent re-occurance following 'preserve' param strategy
            const params = { ...this.route.snapshot.queryParams };
            delete params.sitc;
            this.router.navigate([], { queryParams: params });
          },
        });
      }
    });
  }

  private handleFeedbackValidation(): void {
    this.subs.push(
      this.translate.onLangChange
        .pipe(
          take(1),
          switchMap(() => {
            const userInfoStr = localStorage.getItem('userInfo');
            const userInfoData = userInfoStr ? JSON.parse(userInfoStr) : null;

            if (userInfoData && !this.isExpiredUserInfo(userInfoData.timestamp)) {
              if (this.surveyService.userCostCenterValid(userInfoData.costCenterLocation)) {
                this.handleSurvey();
              }
              return of(null);
            } else {
              return this.userData$.pipe(
                switchMap(value => {
                  return this.scdService.searchUserByGID(value.gid).pipe(map(result => ({ value, result })));
                }),
                tap(({ value, result }) => {
                  const userInfoData: IUserInfo = {
                    costCenterLocation: result[value.gid].costLocationUnit,
                    timestamp: Date.now(),
                  };
                  localStorage.setItem('userInfo', JSON.stringify(userInfoData));
                  if (this.surveyService.userCostCenterValid(result[value.gid].costLocationUnit)) {
                    this.handleSurvey();
                  }
                }),
              );
            }
          }),
        )
        .subscribe(),
    );
  }

  private isExpiredUserInfo(timestamp: number): boolean {
    const expirationTimeInMonth: number = 1;
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() - expirationTimeInMonth);

    return timestamp < expirationDate.getTime();
  }

  private async handleSurvey(): Promise<void> {
    setTimeout(() => {
      this.surveyService.getActiveSurveyConfig().subscribe(result => {
        if (!(result instanceof HttpErrorResponse)) {
          const surveyStatus = this.userConfig.survey && this.userConfig.survey[result.id] && this.userConfig.survey[result.id].status;
          if (surveyStatus !== 'completed' && !this.popupService.getActivePopupConfig([POPUP_TYPE.focus_week_popup])) {
            this.showSurvey(false, result);
          }
        } else {
          console.error(result);
        }
      });
    }, 60000);
  }

  private openSurveyDialog(track: boolean, surveyConfig: ISurveyConfig): void {
    if (track) {
      this.tracking.addTrackingItem(TRACKING_ITEM.button, 'Open survey[from]:' + this.tracking.cleanRoute(this.router.url));
    }
    this.dialog.open(SurveyDialogComponent, {
      data: surveyConfig,
      panelClass: 'surveyDialog',
      disableClose: true,
    });
  }

  private getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private setComponentsVisibility(currentUrl: string): void {
    if (currentUrl.startsWith('/forbidden')) {
      document.title = 'Access denied';
    } else if (currentUrl.startsWith('/mybrandbuilder')) {
      this.showBBheader = true;
      this.showMGheader = false;
      this.showLeadersHeader = false;
      this.showSelfReflectionHeader = false;
      this.showEthicalSelfReflectionHeader = false;
      this.showFooter = true;
      document.title = 'My Brand Builder';
    } else if (currentUrl.startsWith('/leaders')) {
      this.showBBheader = false;
      this.showMGheader = false;
      this.showLeadersHeader = true;
      this.showSelfReflectionHeader = false;
      this.showEthicalSelfReflectionHeader = false;
      this.showFooter = true;
      document.title = 'MyGrowth Leaders';
    } else if (currentUrl.startsWith('/self-reflection-journey')) {
      this.showBBheader = false;
      this.showMGheader = false;
      this.showLeadersHeader = false;
      this.showSelfReflectionHeader = true;
      this.showEthicalSelfReflectionHeader = false;
      this.showFooter = true;
      document.title = 'Self-Reflection Journey';
    } else if (currentUrl.startsWith('/ethical-self-reflection')) {
      this.showBBheader = false;
      this.showMGheader = false;
      this.showLeadersHeader = false;
      this.showSelfReflectionHeader = false;
      this.showEthicalSelfReflectionHeader = true;
      this.showFooter = true;
      document.title = 'Ethical Self-Reflection';
    } else {
      this.showBBheader = false;
      this.showMGheader = true;
      this.showLeadersHeader = false;
      this.showSelfReflectionHeader = false;
      this.showEthicalSelfReflectionHeader = false;
      this.showFooter = true;
      document.title = 'Siemens MyGrowth';
    }
  }
}
