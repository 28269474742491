import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as prodEnvironment } from 'src/environments/environment.prod';
import { ILanguage } from './language.service';
import { Observable } from 'rxjs';
import { TranslationObject } from '../types/translation-object';
import { tap } from 'rxjs/operators';

export enum Stage {
  DEV,
  PROD,
}

export type TranslationChange = {
  action: 'update' | 'rename' | 'create' | 'delete' | 'createTopic';
  path: string;
  value?: string | {};
  newPath?: string;
};

export type UpdateData = {
  lang: string;
  changes: TranslationChange[];
};

@Injectable({
  providedIn: 'root',
})
export class TranslationFileService {
  constructor(private http: HttpClient) {}

  public getFileFromCDN(lang: string, stage: Stage): Observable<TranslationObject> {
    console.error(lang, stage)
    const baseUrl: string = stage === Stage.PROD ? prodEnvironment.translationsUrl : prodEnvironment.translationsUrlDev;
    return this.http.get(
      `${baseUrl}${lang}.json`,
      {
        responseType: 'json',
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          Pragma: 'no-cache',
          Expires: '0'
        }
      }) as Observable<TranslationObject>;
  }

  public downloadFile(lang: string, stage: Stage): void {
    this.getFileFromCDN(lang, stage)
      .pipe(
        tap((translationObject: TranslationObject) => {
          const blob = new Blob([JSON.stringify(translationObject)]);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${lang}.json`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
      );
  }

  public updateFile(data: UpdateData, stage: Stage): Promise<any> {
    return this.http.post(prodEnvironment.backendUrl + `translations${stage === Stage.PROD ? '' : '/dev'}/update`, data).toPromise();
  }

  public createFile(language: ILanguage, stage: Stage): Promise<any> {
    return this.http.post(prodEnvironment.backendUrl + `translations${stage === Stage.PROD ? '' : '/dev'}`, language).toPromise();
  }
}
