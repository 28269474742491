import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'gt-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss'],
})
  export class VideoplayerComponent implements OnChanges {
  @Input() videolink: string;
  @Input() autoplay: boolean;
  @Output() videoFinished = new EventEmitter<void>();
  @Output() videoSkipped = new EventEmitter<{ skippedTo: number}>();
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;
  private previousTime: number = 0;
  private skippedVideo: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {    
    this.updateVideoSource(changes);
  }

  private updateVideoSource(changes: SimpleChanges): void {
    if (changes['videolink']?.currentValue !== changes['videolink']?.previousValue) {  
      if (this.videoPlayer?.nativeElement) {
        const videoElement = this.videoPlayer.nativeElement;
        videoElement.src = changes['videolink'].currentValue; 
        if (this.autoplay) {
          videoElement.play();
        }
      } 
    }
  }

  public getType(): string {
    return 'video/mp4';
  }

  public videoEnded(): void {
    this.videoFinished.next();
  }

  public videoTimeUpdate(event: Event): void {
    const videoElement = event.target as HTMLVideoElement;
    const currentTime = videoElement.currentTime;
    const skipThreshold = 2;
    
    if (currentTime - this.previousTime < skipThreshold) {
      this.skippedVideo = true;
    }
    this.previousTime = currentTime;
  }

  public onVideoSkip(): void {
    const videoElement = this.videoPlayer.nativeElement as HTMLVideoElement;
    const currentTime = videoElement.currentTime;
    this.videoSkipped.next({ skippedTo: currentTime });
  }
}
