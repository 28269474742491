export enum JOURNEY_STEP_TYPE {
  DIMENSIONS = 'dimensions',
  VIDEO = 'video',
  TEXT = 'text',
  OPTION = 'option',
  UNIQUE = 'unique',
  QUIZ = 'quiz',
  SELECTION = 'selection',
  USER_INPUT = 'user_input',
  SUMMARY = 'summary',
  AUDIO = 'audio',
  MULTI_CHOICE = 'multichoice',
  RATING = 'rating',
  CONDITIONAL_OUTPUT = 'conditional_output',
  EXPANDABLE = 'expandable'
}
