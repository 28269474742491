export class IMachineTranslationInput {
  Text: string;
  FromCode: IMachineTranslationLanguageCode;
  ToCode: IMachineTranslationLanguageCode;
  TextContentType: 'text/html';
  glossary?: string;
}

export class IMachineTranslationResponse {
  Text: string;
  DetectedLanguages: string[];
}

export enum IMachineTranslationLanguageCode {
  EN = 'en',
  DE = 'de',
  ES = 'es',
  FR_CA = 'fr-CA',
  PL = 'pl',
  PT_BR = 'pt',
  ZH = 'zh-Hans',
}
