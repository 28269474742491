import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'gt-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss'],
})
export class VideoplayerComponent {
  @Input() videolink: string;
  @Input() autoplay: boolean;
  @Output() videoFinished = new EventEmitter<void>();
  @Output() videoSkipped = new EventEmitter<{ skippedTo: number}>();
  @ViewChild('videoPlayer') videoPlayer!: ElementRef;
  private previousTime: number = 0;
  private skippedVideo: boolean = false;

  public getType(): string {
    return 'video/mp4';
  }

  public videoEnded(): void {
    this.videoFinished.next();
  }

  public videoTimeUpdate(event: Event): void {
    const videoElement = event.target as HTMLVideoElement;
    const currentTime = videoElement.currentTime;
    const skipThreshold = 2;
    // Check if the user skipped ahead by comparing current time with previous time
    if (currentTime - this.previousTime < skipThreshold) {
      this.skippedVideo = true;
    }
    this.previousTime = currentTime;
  }

  onVideoSkip(): void {
    const videoElement = this.videoPlayer.nativeElement as HTMLVideoElement;
    const currentTime = videoElement.currentTime;
    this.videoSkipped.next({ skippedTo: currentTime });
  }
}
