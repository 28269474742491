import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IIllustrationStyle } from '../../interfaces/IIllustrationStyle';

@Component({
  selector: 'gt-content-section',
  templateUrl: './content-section.component.html',
  styleUrls: ['./content-section.component.scss'],
})
export class ContentSectionComponent {
  @Input() scheme: 'sand' | 'dark' | 'light' = 'dark';
  @Input() illustrationMobilePosition: 'right-to-title' | 'below-desc';
  @Input() imagePositionWhenNextToCopy: 'right' | 'left';
  @Input() imagePositionWhenBelowCopy: 'right' | 'left';
  @Input() size: 'normal' | 'reducedForPageNavigation' = 'normal';
  @Input() hasImage: boolean = true;
  @Input() hasExportPdf: boolean = false;
  @Input() illustrationUrl: string;
  @Input() illustrationSize: string;
  @Input() animationColor: string = '#fe8389';
  @Input() illustrationStyle: IIllustrationStyle = { marginTop: '0', marginLeft: '0', width: '37%', height: '100%', color: '#00D7A0' };
  @Input() hasMarginBottom = false;
  @Input() noTitle = false;
  @Input() imageAlignment: 'start' | 'center' | 'end'; //default is end
  @Input() imgAltText: string = '';
  @Input() animType: 'z' | 'linear' | 'wavy' = 'z';

  @Input() sectionContent: {
    subTitle?: string;
    title1?: string;
    titleAnim?: string;
    title2?: string;
    title2Anim?: string;
    desc?: string;
    imgUrl?: string;
    btnUrl?: string;
    btnText?: string;
    textLinkUrl?: string;
    textLink?: string;
  };

  constructor(public translateService: TranslateService) {}
}
