import { ApplicationRef, APP_INITIALIZER, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './static/header/header.component';
import { FooterComponent } from './static/footer/footer.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { TokenInjector } from './auth/services/token.injector.service';
import { SharedModule } from './shared/shared.module';
import { SearchComponent } from './static/search/search.component';
import { LeadersHeaderComponent } from './static/leaders-header/leaders-header.component';
import { AuthService } from './auth/services/new-auth.service';
import { filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthGmsAngularClientModule } from './auth/auth.module';
import { TrackingSuiteModule, TRACKING_CONFIG } from '@siemens/tracking-suite-angular-lib';
import { UserState } from './shared/state-management/user/user.state';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { ApplicationHeaderComponent } from './static/application-header/application-header.component';

// for local development only - make sure tha you have the latest version of en.json from the S3!!

//  export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
//  	return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
//  }

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, environment.translationsUrl, '.json');
}

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, SearchComponent, LeadersHeaderComponent, ApplicationHeaderComponent],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    AuthGmsAngularClientModule,
    TrackingSuiteModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxsModule.forRoot([UserState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInjector,
      multi: true,
    },
    {
      provide: TRACKING_CONFIG,
      useValue: {
        apiKey: environment.newTracking.apiKey,
        //  apiVersion: 'v2', // optional, default = 'v2'
        endpointStage: environment.newTracking.endpointStage,
      },
    },
    Title,
  ],
})
export class AppModule implements DoBootstrap {
  constructor(private authService: AuthService) {}

  public ngDoBootstrap(appRef: ApplicationRef): void {
    this.authService.isAuthenticated$
      .pipe(
        filter(auth => !!auth),
        tap(() => appRef.bootstrap(AppComponent)),
      )
      .subscribe();
  }
}

function initializeAppFactory(authService: AuthService): () => Observable<boolean> {
  return () =>
    authService.isAuthenticated$.pipe(
      filter(auth => !auth),
      tap(() => authService.login()),
    );
}
