import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';
import { MYG_LANGUAGE_CODE } from 'src/app/shared/enums/language-code.enum';
import { ILanguage, LanguageService } from 'src/app/shared/services/language.service';
import { LoadingIndicatorService } from 'src/app/shared/services/loading-indicator.service';

@Component({
  selector: 'gt-application-header',
  standalone: false,
  templateUrl: './application-header.component.html',
  styleUrl: './application-header.component.scss',
})
export class ApplicationHeaderComponent {
  @Input() headerTitle: string = 'Application';
  @Input() headerTitleRoute: string = '/';
  @Input() availableLanguages: MYG_LANGUAGE_CODE[] = [MYG_LANGUAGE_CODE.EN];
  @Input() closeApplicationBtnLabel: string = 'shared.close';
  @Input() feedbackBtnLabel?: string;
  @Input() feedbackEmail: string = '';

  @Output() applicationClosed = new EventEmitter<void>();

  private destroy$: Subject<boolean> = new Subject<boolean>();
  protected currentLang: ILanguage = { name: 'English', code: MYG_LANGUAGE_CODE.EN, rssCode: '', visible: true };
  protected languages: ILanguage[] = [];
  protected showCloseBtn: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public languageService: LanguageService,
    public translate: TranslateService,
    private loadingIndicator: LoadingIndicatorService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  async ngOnInit(): Promise<void> {
    this.getLanguages();
    this.setLanguage();
    this.setBtnVisibility();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private getLanguages(): void {
    this.languageService
      .getLanguagesObservable()
      .pipe(
        map(languages => languages.filter(s => this.availableLanguages.includes(s.code))),
        tap(languages => (this.languages = languages)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setLanguage(): void {
    this.route.queryParams
      .pipe(
        debounceTime(200),
        tap(params => {
          this.loadingIndicator.updateLoadingIndicatorStatus(true);
          if (params['lang']) {
            const langCode = this.languages.filter(s => s.code === params['lang']);
            const defaultObject = { name: 'English', code: MYG_LANGUAGE_CODE.EN, rssCode: '', visible: true };
            this.currentLang = langCode && langCode.length ? langCode[0] : defaultObject;
            this.translate.use(this.currentLang.code);
            this.loadingIndicator.updateLoadingIndicatorStatus(false);
          } else {
            this.translate.use('en');
            this.loadingIndicator.updateLoadingIndicatorStatus(false);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setBtnVisibility(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => (this.showCloseBtn = event.url.includes('pdf-export') ? false : true)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public close(): void {
    this.applicationClosed.emit();
  }

  public changeLanguage(lang: string): void {
    this.translate.use(lang);
    this.currentLang = this.languages.filter(s => s.code === lang)[0];
    this.document.documentElement.lang = this.currentLang.code;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { lang: this.currentLang.code },
    });
  }
}
