import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { VideoService } from '../../services/video.service';
import { takeUntil, tap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'gt-video-section',
  templateUrl: './video-section.component.html',
  styleUrls: ['./video-section.component.scss'],
})
export class VideoSectionComponent implements OnInit {
  @Input() scheme: 'sand' | 'dark' | 'light' = 'dark';
  @Input() illustrationMobilePosition: 'right-to-title' | 'below-desc';
  @Input() imagePositionWhenNextToCopy: 'right' | 'left';
  @Input() imagePositionWhenBelowCopy: 'right' | 'left';
  @Input() size: 'normal' | 'reducedForPageNavigation' = 'normal';
  @Input() hasImage: boolean = true;
  @Input() illustrationUrl: string;
  @Input() illustrationSize: string;
  @Input() animationColor: string = '#fe8389';
  @Input() illustrationStyle: { top?: string; marginLeft?: string; left?: string; width: string; height?: string; marginTop?: string } = {
    marginTop: '0',
    marginLeft: '0',
    width: '37%',
    height: '100%',
  };
  @Input() hasMarginBottom = false;
  @Input() smallTitle = false;
  @Input() imageAlignment: 'start' | 'center' | 'end'; //default is end
  @Input() imgAltText: string = '';
  @Input() animType: 'z' | 'linear' | 'wavy' = 'z';

  @Input() sectionContent: {
    subTitle?: string;
    title1?: string;
    titleAnim?: string;
    title2?: string;
    desc1?: string;
    desc2?: string;
    imgUrl?: string;
    downloadBtnUrl?: string;
    downloadBtnText?: string;
    btnUrl?: string;
    btnText?: string;
    textLinkUrl?: string;
    textLink?: string;
    videoName?: string;
  };

  public videoUrl: string;

  private destroy$ = new Subject<void>();

  constructor(
    public translateService: TranslateService,
    private videoService: VideoService,
  ) {}

  ngOnInit(): void {
    this.getVideoUrlOnLangChange();
  }

  ngOnChanges(changes: SimpleChanges): void {    
    if (changes['sectionContent']?.currentValue !== changes['sectionContent']?.previousValue) {
      this.loadVideoUrlByVideoNameAndLanguage(this.sectionContent.videoName, this.translateService.currentLang)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  private getVideoUrlOnLangChange(): void {
    this.translateService.onLangChange
      .pipe(
        switchMap(event =>
          this.loadVideoUrlByVideoNameAndLanguage(this.sectionContent.videoName, event.lang)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
  
  private loadVideoUrlByVideoNameAndLanguage(videoName: string, lang?: string): Observable<string> {
    return this.videoService.getVideoUrl(videoName, lang).pipe(
      tap(url => (this.videoUrl = url))
    );
  }
}
