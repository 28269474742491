import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VisibilityDialogComponent } from './visibility-dialog/visibility-dialog.component';
import { AddTopicDialogComponent } from './add-topic-dialog/add-topic-dialog.component';
import { AddItemDialogComponent } from './add-item-dialog/add-item-dialog.component';
import { AddLanguageDialogComponent } from './add-language-dialog/add-language-dialog.component';
import _ from 'lodash';
import { Stage, TranslationFileService, TranslationChange } from 'src/app/shared/services/translation-file.service';
import { ILanguage, LanguageService } from 'src/app/shared/services/language.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DeleteItemDialogComponent } from './delete-item-dialog/delete-item-dialog.component';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { UserRolesEnum } from 'src/app/shared/enums/user_roles.enum';
import { Select } from '@ngxs/store';
import { catchError, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { UserState } from 'src/app/shared/state-management/user/user.state';
import { IUserData } from 'src/app/shared/interfaces/IUserData';
import { MYG_LANGUAGE_CODE } from 'src/app/shared/enums/language-code.enum';
import type { TranslationObject } from '../../shared/types/translation-object';

@Component({
  selector: 'gt-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss'],
})
export class TranslationsComponent implements OnInit, OnDestroy {
  @Select(UserState.loggedUser) userData$: Observable<IUserData>;
  @Select(UserState.roles) roles$: Observable<UserRolesEnum[]>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private visabilityDialog: MatDialog,
    private addTopicDialog: MatDialog,
    private addItemDialog: MatDialog,
    private addLanguageDialog: MatDialog,
    private translationFileService: TranslationFileService,
    private languageService: LanguageService,
  ) {}

  public availableLanguages: ILanguage[];
  public selectedLanguage: MYG_LANGUAGE_CODE;
  public isLoading: boolean = false;
  public isLoadingUpdates: boolean = false;
  public isLoadingLanguages: boolean = false;
  public isSaving: boolean = false;
  public translationObject: TranslationObject = {};
  public existsInDev: TranslationObject = {};
  public existsInProd: TranslationObject = {};
  public addToProd: string[] = [];
  public deleteFromProd: string[] = [];
  public englishOrig: TranslationObject = {};
  public isAdmin: boolean = false;
  public canAddKeys: boolean = false;
  public isTranslationManager: boolean = false;
  public selectedTab: number = 0;
  public changes: TranslationChange[] = [];
  public showOnlyEmpty: boolean = false;
  private origTranslationObject: TranslationObject = {};
  private emptyValuesTranslationObject: TranslationObject = {};
  private readonly canAddKeysList = [
    'z002ywjp',
    'z004hm3n',
    'z004ks7f',
    'z003cw0f',
    'z004vjdm',
    'z004j4ns',
    'z004nadf',
    'z004j8hw',
    'z004pkxe',
    'z005068r',
    'z005025r',
    'z003nvcc',
  ];

  public async ngOnInit(): Promise<void> {
    this.isLoadingLanguages = true;
    this.isUserAwesome();
    this.getUserRoles();

    this.languageService
      .getLanguages()
      .then(res => {
        this.availableLanguages = res;
        this.isLoadingLanguages = false;
      })
      .catch(err => console.error('Could not fetch languages', err));
  }

  private getUserRoles(): void {
    this.roles$
      .pipe(
        filter(roles => !!roles),
        tap(roles => {
          this.isAdmin = roles.includes(UserRolesEnum.ADMIN);
          this.isTranslationManager = roles.includes(UserRolesEnum.TRANSLATION_MANAGER);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private isUserAwesome(): void {
    this.userData$
      .pipe(
        tap(userData => {
          const userGID = userData.gid.toLowerCase();
          if (this.canAddKeysList.includes(userGID)) {
            this.canAddKeys = true;
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public onLanguageSelect(lang: MYG_LANGUAGE_CODE): void {
    this.selectedTab = 0;
    this.selectedLanguage = lang;
    this.isLoading = true;

    this.translationFileService
      .getFileFromCDN(this.selectedLanguage, Stage.DEV)
      .pipe(
        tap((translationObject: TranslationObject): void => {
          this.translationObject = translationObject;
          this.origTranslationObject = translationObject;
          this.emptyValuesTranslationObject = emptyValues(this.translationObject);
          this.isLoading = false;
        }),
        catchError((error) => {
          console.error('Could not fetch file', error);
          return of(null);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.translationFileService
      .getFileFromCDN('en', Stage.DEV)
        .pipe(
          tap((translationObject: TranslationObject): void => {
            this.englishOrig = translationObject;
          }),
          catchError((error) => {
            console.error('Could not fetch file', error);
            return of(null);
          }),
          takeUntil(this.destroy$)
        )
      .subscribe();
  }

  public onSearch(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    if (value === '') {
      this.translationObject = this.origTranslationObject;
    } else {
      const flatObject = flattenObject(this.origTranslationObject);
      const filteredObject = _.pickBy(flatObject, (_: string, key: string) => {
        return key?.toLowerCase().includes(value.toLowerCase()) || _?.toLowerCase().includes(value.toLowerCase());
      });
      const entries = Object.entries(filteredObject);
      let searchedObject = {};
      for (let i = 0; i < entries.length; i++) {
        _.set(searchedObject, entries[i][0], entries[i][1]);
      }
      this.translationObject = searchedObject;
    }
  }

  public handleChange(event: TranslationChange): void {
    event.path = event.path.startsWith('.') ? event.path.substring(1) : event.path;

    const existingIndex = this.changes.findIndex(c => c.path === event.path);
    if (existingIndex !== -1 && this.changes[existingIndex].action === 'create') {
      this.changes[existingIndex].value = event.value;
    } else {
      this.changes = this.changes.filter(c => c.path !== event.path).concat(event);
    }

    switch (event.action) {
      case 'update':
        _.set(this.translationObject, event.path, event.value);
        _.set(this.origTranslationObject, event.path, event.value);
        break;
      case 'rename':
        const value = _.get(this.translationObject, event.path);
        _.unset(this.translationObject, event.path);
        _.set(this.translationObject, event.newPath, value);
        _.unset(this.origTranslationObject, event.path);
        _.set(this.origTranslationObject, event.newPath, value);
        break;
      case 'create':
        _.set(this.translationObject, event.path, event.value);
        _.set(this.origTranslationObject, event.path, event.value);
        break;
      case 'delete':
        _.unset(this.translationObject, event.path);
        _.unset(this.origTranslationObject, event.path);
        break;
    }

    this.emptyValuesTranslationObject = emptyValues(this.translationObject);
  }

  public onSave(): void {
    this.isSaving = true;

    const updateData = {
      lang: this.selectedLanguage,
      changes: this.changes,
    };

    this.translationFileService
      .updateFile(updateData, Stage.DEV)
      .then(() => {
        this.isSaving = false;
        this.changes = [];
      })
      .catch(err => console.error('Could not update', err));
  }

  public onShowVisabilityDialog(): void {
    const data = { languages: this.availableLanguages };
    const dialogRef = this.visabilityDialog.open(VisibilityDialogComponent, { data });
    dialogRef
      .afterClosed()
      .pipe(
        filter(res => !!res),
        tap(res => {
          this.availableLanguages = res.languages;

          for (let i = 0; i < res.languages.length; i++) {
            this.languageService
              .updateLanguage({
                code: res.languages[i].code,
                name: res.languages[i].name,
                rssCode: res.languages[i].rssCode,
                visible: res.languages[i].visible,
              })
              .then(() => console.log('Languages successfully updated'))
              .catch(err => console.error('Could not update languages', err));
          }
        }),
      )
      .subscribe();
  }

  public onAddTopic(path: string): void {
    const data = { keyName: '' };
    const dialogRef = this.addTopicDialog.open(AddTopicDialogComponent, { data });
    dialogRef
      .afterClosed()
      .pipe(
        filter(topic => !!topic),
        tap(topic => {
          this.handleChange({
            action: 'create',
            path: path ? `${path}.${topic.keyName}` : topic.keyName,
            value: {},
          });
        }),
      )
      .subscribe();
  }

  public onAddItem(path: string): void {
    const data = { keyName: '', valueName: '' };
    let isSameItemDeletedAndCreated = false;
    const dialogRef = this.addItemDialog.open(AddItemDialogComponent, { data });
    dialogRef
      .afterClosed()
      .pipe(
        filter(item => !!item),
        tap(item => {
          isSameItemDeletedAndCreated =
            this.changes.filter(change => change.action === 'delete' && change.path === `${path}.${item.keyName}`.substring(1)).length > 0;
          if (isSameItemDeletedAndCreated) {
            this.handleChange({
              action: 'update',
              path: path ? `${path}.${item.keyName}` : item.keyName,
              value: item.valueName,
            });
          } else {
            this.handleChange({
              action: 'create',
              path: path ? `${path}.${item.keyName}` : item.keyName,
              value: item.valueName,
            });
          }
        }),
      )
      .subscribe();
  }

  public onRemoveItem(path: string): void {
    const dialogRef = this.addTopicDialog.open(DeleteItemDialogComponent);
    dialogRef
      .afterClosed()
      .pipe(
        filter(res => !!res),
        tap(() => {
          this.handleChange({
            action: 'delete',
            path: path,
          });
          const pathToBeDeleted = path;
          this.changes = this.changes.filter(change => !(change.action === 'create' && change.path === pathToBeDeleted.substring(1)));
        }),
      )
      .subscribe();
  }

  public onRemoveTopic(changes: TranslationChange[]): void {
    const dialogRef = this.addTopicDialog.open(DeleteItemDialogComponent);
    dialogRef
      .afterClosed()
      .pipe(
        filter(res => !!res),
        tap(() => {
          changes.forEach(change => {
            this.handleChange({
              action: 'delete',
              path: change.path,
            });
            const pathToBeDeleted = change.path;
            this.changes = this.changes.filter(change => !(change.action === 'create' && change.path === pathToBeDeleted.substring(1)));
          });
        }),
      )
      .subscribe();
  }

  public onAddLanguage(): void {
    const data = { code: '', name: '', rssCode: '' };
    const dialogRef = this.addLanguageDialog.open(AddLanguageDialogComponent, { data });
    dialogRef
      .afterClosed()
      .pipe(
        filter(lang => !!lang),
        tap(lang => {
          const language: ILanguage = {
            code: lang.code,
            visible: !environment.production,
            name: lang.name,
            rssCode: lang.rssCode,
          };

          this.availableLanguages = [...this.availableLanguages, language];

          this.translationFileService
            .createFile(language, Stage.DEV)
            .then(() => console.log('Language added successfully'))
            .catch(err => console.error('Could not add language', err));

          this.translationFileService
            .createFile(language, Stage.PROD)
            .then(() => console.log('Language added successfully'))
            .catch(err => console.error('Could not add language', err));
        }),
      )
      .subscribe();
  }

  public onSelectAdd({ path, checked }): void {
    if (checked) {
      this.addToProd = [...this.addToProd, path];
    } else {
      this.addToProd = this.addToProd.filter(p => p !== path);
    }
  }

  public areAllDevSelected(): boolean {
    return this.addToProd.length === Object.keys(flattenObject(this.existsInDev)).map(k => `.${k}`).length && this.addToProd.length > 0;
  }

  public areAllProdSelected(): boolean {
    return this.deleteFromProd.length === Object.keys(flattenObject(this.existsInProd)).map(k => `.${k}`).length && this.addToProd.length > 0;
  }

  public onSelectAddAll(): void {
    this.addToProd = Object.keys(flattenObject(this.existsInDev)).map(k => `.${k}`);
  }

  public onDeselectAddAll(): void {
    this.addToProd = [];
  }

  public onSelectDelete({ path, checked }): void {
    if (checked) {
      this.deleteFromProd = [...this.deleteFromProd, path];
    } else {
      this.deleteFromProd = this.deleteFromProd.filter(p => p !== path);
    }
  }

  public onSelectDeleteAll(): void {
    this.deleteFromProd = Object.keys(flattenObject(this.existsInProd)).map(k => `.${k}`);
  }

  public onDeselectDeleteAll(): void {
    this.deleteFromProd = [];
  }

  public onUpdate(): void {
    if (!this.selectedLanguage) return;

    this.isLoadingUpdates = true;

    const devToProdChanges: TranslationChange[] = this.addToProd.map(p => {
      const path = p.startsWith('.') ? p.substring(1) : p;
      return {
        action: 'update',
        path,
        value: _.get(this.translationObject, path, ''),
      };
    });

    const deleteFromProdChanges: TranslationChange[] = this.deleteFromProd.map(p => {
      const path = p.startsWith('.') ? p.substring(1) : p;
      return {
        action: 'delete',
        path,
      };
    });

    const updateData = {
      lang: this.selectedLanguage,
      stage: 'prod',
      changes: [...deleteFromProdChanges, ...devToProdChanges],
    };

    this.translationFileService
      .updateFile(updateData, Stage.PROD)
      .then(() => {
        this.loadDiff();
      })
      .catch(err => console.error('Could not update', err));
  }

  public toggleOnlyEmpty(event: MatCheckboxChange): void {
    this.showOnlyEmpty = event.checked;

    if (!this.showOnlyEmpty) {
      this.translationObject = this.origTranslationObject;
    } else {
      this.translationObject = this.emptyValuesTranslationObject;
    }
  }

  public noEmptyValuesExist(): boolean {
    return _.isEmpty(this.emptyValuesTranslationObject);
  }

  private async loadDiff(): Promise<void> {
    this.isLoadingUpdates = true;

    let devObj = {};
    let prodObj = {};

    try {
      [devObj, prodObj] = await Promise.all([
        firstValueFrom(this.translationFileService.getFileFromCDN(this.selectedLanguage, Stage.DEV)),
        firstValueFrom(this.translationFileService.getFileFromCDN(this.selectedLanguage, Stage.PROD))
      ]);
    } catch (err) {
      console.error(err);
    }

    this.existsInDev = calcDiffObj(devObj, prodObj, true);
    this.existsInProd = calcDiffObj(prodObj, devObj, false);

    this.isLoadingUpdates = false;
  }

  public async onTabChange(event: MatTabChangeEvent): Promise<void> {
    if (event.index === 1) {
      this.loadDiff();
    }

    this.selectedTab = event.index;
  }
}

/*
  Flatten object to single dimension:

  obj = {
    a: { b : 5, c: 6, d: { e: 7 } },
    f: { g: 8 }
  }

  return = {
    a.b: 5,
    a.c: 6,
    a.d.e: 7,
    f.g: 8
  }
*/
export const flattenObject = (obj = {}) => {
  const result = {};

  const flatten = (collection, prefix = '', suffix = '') => {
    _.forEach(collection, (value, key) => {
      const path = `${prefix}${key}${suffix}`;

      if (_.isArray(value)) {
        flatten(value, `${path}[`, ']');
      } else if (_.isPlainObject(value)) {
        flatten(value, `${path}.`);
      } else {
        result[path] = value;
      }
    });
  };

  flatten(obj);

  return result;
};

const calcDiffObj = (obj1: TranslationObject, obj2: TranslationObject, checkValue: boolean): TranslationObject => {
  const flatObj1 = flattenObject(obj1);
  const entries = Object.entries(flatObj1);

  let res = {};
  for (let i = 0; i < entries.length; i++) {
    const path = entries[i][0];
    const value = entries[i][1];

    if (!_.has(obj2, path) || (checkValue && value !== _.get(obj2, path))) {
      _.set(res, path, _.get(obj1, path, ''));
    }
  }

  return res;
};

const emptyValues = (obj: TranslationObject): TranslationObject => {
  const flatObj = flattenObject(obj);

  let res = {};
  for (let i = 0; i < Object.keys(flatObj).length; i++) {
    const path = Object.keys(flatObj)[i];
    const value = flatObj[path];

    if (value === '') {
      _.set(res, path, _.get(obj, path, ''));
    }
  }

  return res;
};
